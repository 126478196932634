import React from 'react';
import PropTypes from 'prop-types';

import ApiForm from '../api-form';
import Field from '../form/bootstrap-field';


const NewAppForm = ({onSubmit, onSuccess}) => (
  
  <ApiForm
    onSubmit={onSubmit}
    onSuccess={onSuccess}
  >
    {({getProps, submit, errMsg, loading}) => 

      <form onSubmit={submit}>
        <div className="modal-header">
          <h4 className="modal-title">Create a new app</h4>
        </div>
        <div className="modal-body">
          <Field 
            {...getProps('name')}
            label="App name"
            placeholder="my-app"
            autoFocus
            required
          />
        </div>
        <div className="modal-footer">
          <button 
            className="btn btn-primary"
            type="submit"
            disabled={loading}
          >
            {loading 
              ? <i className="fa fa-spinner fa-spin"/>
              : 'Create'
            }
          </button>
        </div>
      </form>

    }
  </ApiForm>
  
);


NewAppForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default NewAppForm;
