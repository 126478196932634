import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MultiSelect from '../../form/multi-select';


export default class MemberFormLangSelect extends React.Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  }

  render() {
    return (
      <MultiSelect
        {...this.props}
        options={this.getOptions()}
        onChange={this.onChange}
        //resetValue="*"
      />
    );
  }

  getOptions = () => {
    const {app} = this.props;

    const langOptions = app.langs.map(lang => {
      return { value: lang.id, label: `${lang.title} (${lang.name})` }
    });

    const allLangOptions = [
      { value: '*', label: 'All languages' }
    ].concat(langOptions);

    return allLangOptions;
  }

  onChange = (event) => {
    const ids = event.target.value;

    // 'All languages' is default if none are selected
    if (ids.length === 0) {
      event.target.value = ['*'];
    }

    // or if it has just been selected
    if (_.last(ids) === '*') {
      event.target.value = ['*'];
    } else {
      event.target.value = _.without(ids, '*');
    }

    this.props.onChange(event);
  }

};
