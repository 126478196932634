export default (state = null, action) => {
  switch (action.type) {
    case 'USER_RECEIVE':
      if (action.payload) {
        return Object.assign({}, state, action.payload);
      } else {
        return null;
      }

    default:
      return state;
  }
};
