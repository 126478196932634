import React from 'react';


export default function Modal({
  title,
  children,
  footer,
}) {
  return (
    <>
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
      </div>
      <div className="modal-body">
        {children}
      </div>
      {footer && (
        <div className="modal-footer">
          {footer}
        </div>
      )}
    </>
  );
}
