import store from '../store';

/**
 * @param {Object} app
 * @param {String} langId
 *
 * @return {String} title
 */
export const getById = function(appId) {
  const { apps } = store.getState();

  return Object.values(apps).find(({ id }) => id === appId);
};

export function getPermissionsFromRole(role) {
  return {
    editor: ['create', 'update'],
    publisher: ['create', 'update', 'publish'],
    admin: ['create', 'update', 'publish', 'admin'],
  }[role];
}

export function getRoleFromPermissions(permissions = []) {
  if (permissions.includes('admin')) return 'admin';

  if (permissions.includes('publish')) return 'publisher';

  return 'editor';
}