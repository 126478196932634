import {keyBy} from 'lodash';


export default (state = {}, {type, payload}) => {
  switch (type) {
    case 'APPS_RECEIVE':
      const appsByName = keyBy(payload, 'name');

      return Object.assign({}, state, appsByName);

    default:
      return state;
  }
};
