import React, { useState } from 'react';

import LoginForm from './login-form';
import ForgotPasswordForm from './forgot-password-form';
import ResetPasswordForm from './reset-password-form';


export default function LoginController({
  dispatch,
  onSuccess,
}) {
  const [view, setView] = useState('login');

  const View = {
    'login': LoginForm,
    'forgotPassword': ForgotPasswordForm,
    'resetPassword': ResetPasswordForm,
  }[view];

  if (!View) return null;
  
  return (
    <View
      setView={setView}
      dispatch={dispatch}
      onSuccess={onSuccess}
    />
  );
}
