/**
 * Renders child components as a list of radio items.
 * Each child should have a "value" property.
 */
 import React from 'react';
 
 
export default function FormRadiolist({
  name,
  value: selectedValue,
  onChange,
  children,
}) {
  function toggleSelected(value) {
    onChange({
      target: {
        name,
        value
      }
    });
  }

  return (
    <div className="list-group" style={{maxHeight: 300, overflow: 'auto'}}>
      {React.Children.map(children, (item) => {
        const { value } = item.props;

        return (
          <label key={value} className="list-group-item clearfix" style={{fontWeight: 'normal'}}>
            <div className="pull-left" style={{width: 25}}>
              <input 
                type="radio"
                name={name}
                checked={value === selectedValue}
                onChange={() => toggleSelected(value)}
              />
            </div>

            {item}
          </label>
        );
      })}
    </div>
  );
}
