import React from 'react';

import ApiForm from '../../../api-form';
import Modal from '../../../modal';
import Field from '../../../form/bootstrap-field';
import LangSelectSingle from '../../lang-select-single';


export default function TranslationCsvExportForm({ app, onSubmit, onSuccess }) {
  const initialValue = {
    sourceLangId: app.langs[0].id,
  };

  return (
    <ApiForm
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      initialValue={initialValue}
    >
      {({ getProps, submit, errMsg, loading, formData, ...rest }) => (
        <form onSubmit={submit}>
          <Modal
            title="Export CSV content"
            footer={(
              <button 
                className="btn btn-primary"
                type="submit"
                disabled={loading}
              >
                OK
              </button>
            )}
          >
            <>
              <Field
                {...getProps('sourceLangId')}
                label="Source language"
                required
              >
                <LangSelectSingle
                  {...getProps('sourceLangId')}
                  langs={app.langs.filter(({ id }) => id !== formData.destinationLangId)}
                  required
                />
              </Field>
              <Field
                {...getProps('destinationLangId')}
                label="Destination language"
                required
              >
                <LangSelectSingle
                  {...getProps('destinationLangId')}
                  langs={app.langs.filter(({ id }) => id !== formData.sourceLangId)}
                  required
                />
              </Field>

              {errMsg &&
                <div className="alert alert-danger">
                  {errMsg}
                </div>
              }
            </>
          </Modal>
        </form>
      )}
    </ApiForm>
  );
}
