import React from 'react';
import PropTypes from 'prop-types';

import ApiForm from '../../api-form';
import Field from '../../form/bootstrap-field';
import LangSelectSingle from '../lang-select-single';


const ExportForm = ({app, initialValue, onSubmit, onSuccess}) => (
  
  <ApiForm
    onSubmit={onSubmit}
    onSuccess={onSuccess}
    initialValue={initialValue}
  >
    {({getProps, submit, errMsg, loading}) => 

      <form onSubmit={submit}>
        <div className="modal-header">
          <h4 className="modal-title">Export JSON content</h4>
        </div>
        <div className="modal-body">
          <Field
            {...getProps('langName')}
            label="Language"
            required
          >
            <LangSelectSingle
              valueType="name"
              langs={app.langs}
            />
          </Field>

          <Field
            {...getProps('path')}
            label="Path / container name"
            placeholder="main"
            required
          />

          {errMsg &&
            <div className="alert alert-danger">
              {errMsg}
            </div>
          }
        </div>
        <div className="modal-footer">
          <button 
            className="btn btn-primary"
            type="submit"
            disabled={loading}
          >
            {loading 
              ? <i className="fa fa-spinner fa-spin"/>
              : 'Export'
            }
          </button>
        </div>
      </form>

    }
  </ApiForm>
  
);


ExportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default ExportForm;
