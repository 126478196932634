/**
 * A button which wraps displays a spinner and sets itself to disabled while submitting an API
 * request.
 *
 * To use it, pass an onClick function as prop which returns a promise; This usually would be the
 * result of an action to the API.
 */
import React, { useState } from 'react';


export default function ApiButton({
  bsType = 'primary',
  block = false,
  disabled,
  onClick,
  children,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  function handleClick() {
    setLoading(true);

    onClick()
      .finally(() => setLoading(false));
  }

  let className = disabled ? 'btn btn-default' : `btn btn-${bsType}`;

  if (block) className += ' btn-block';

  return (
    <button
      type="button"
      className={className}
      disabled={disabled || loading}
      {...rest}
      onClick={handleClick}
    >
      {(loading
        ? <i className="fa fa-spinner fa-spin" />
        : children
      )}
    </button>
  );
}
