import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

import reducers from './reducers';


//Middleware for all environments
const middlewares = [
  thunkMiddleware
];


//Dev only middleware
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({
    collapsed: true
  }));
}


//Initialise app state store
let store = createStore(
  reducers, 
  applyMiddleware(...middlewares)
);


export default store;
