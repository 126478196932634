import React, { useState } from 'react';
import PapaCsv from 'papaparse';

const styles = {
  input: {
    display: 'none',
  },
};


export default function ImportButton({
  onReceiveData,
  children = 'Import',
}) {
  const [loading, setLoading] = useState(false);

  function onSelectFile(event) {
    const file = event.target.files[0];

    setLoading(true);

    return PapaCsv.parse(file, {
      header: true,
      complete: (results) => {
        setLoading(false);

        onReceiveData(results)
      },
    });
  }

  return (
    <label className="btn btn-default btn-file" disabled={loading}>
      <i className="fa fa-upload" /> {children}
      <input
        type="file"
        accept={'text/csv'}
        style={styles.input}
        onChange={onSelectFile}
      />
    </label>
  );
}
