import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

import wurd, {WurdText, WurdObject} from 'wurd-react';
import {Modal} from 'react-bootstrap';


const wurdSection = 'common.modalForm';


const CrudList = createClass({

  propTypes: {
    title: PropTypes.node,
    items: PropTypes.array.isRequired,
    Form: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.object,            //Optional default value when creating a new item. Use it to set defaults
    formProps: PropTypes.object,               //Optional props to pass to 'create' and 'update' forms
    canEdit: PropTypes.func,                   //Return true or false to determine if the item can be updated
  },

  getDefaultProps() {
    return {
      initialValue: {}
    }
  },

  getInitialState() {
    return {
      modal: null
    };
  },

  render() {
    const {title, items} = this.props;
    const {modal} = this.state;

    return (
      <div>
        <div className="clearfix">
          <button 
            type="button"
            className="btn btn-link pull-right"
            onClick={this.add}
          >
            <i className="fa fa-plus" />
          </button>
          <h4>{title}</h4>
        </div>
        <ul className="list-group">
          {!!items.length
            ? this.renderItems()
            : this.renderEmpty()
          }          
        </ul>

        <Modal show={!!modal} onHide={this.closeModal}>
          {modal}
        </Modal>
      </div>
    );
  },

  renderEmpty() {
    return (
      <li className="list-group-item text-muted">
        <em>None yet, click '+' to add</em>
      </li>
    );
  },

  renderItems() {
    const {items, children, canEdit} = this.props;

    return items.map((item, i) => {
      const editable = canEdit ? canEdit(item) : true;

      return (
        <li 
          key={i} 
          className={`list-group-item clearfix ${!editable && 'disabled'}`}
          style={{cursor: editable ? 'pointer' : ''}}
          onClick={this.edit.bind(null, item)}
        >
          {children && React.cloneElement(children, {item: item})}
        </li>
      );
    });
  },

  add() {
    const {Form, initialValue, actions, formProps} = this.props;

    this.openModal(
      <Form
        initialValue={initialValue}
        onSubmit={actions.create}
        onSuccess={this.onChange}
        submitText={<WurdText id={`${wurdSection}.create`} />}
        modal
        onHideModal={this.closeModal}
        mode="create"
        {...formProps}
      />
    );
  },

  edit(item) {
    const {Form, formProps, actions, canEdit} = this.props;

    if (canEdit && !canEdit(item)) return;

    this.openModal(
      <Form
        initialValue={item}
        onSubmit={actions.update.bind(null, item.id)}
        onSuccess={this.onChange}
        //submitText={<WurdText id={`${wurdSection}.update`} />}
        //modal
        //onHideModal={this.closeModal}
        deleteButton={<WurdObject id={wurdSection} keys="delete,confirmDelete">{wurd.text(`${wurdSection}.delete`)}</WurdObject>}
        onDelete={this.delete.bind(null, item)}
        mode="update"
        {...formProps}
      />
    );
  },

  delete(item) {
    if (!window.confirm(wurd.text(`${wurdSection}.confirmDelete`))) return;

    this.props.actions.delete(item.id)
      .then(this.onChange)
  },

  onChange() {
    this.props.onChange();
    this.closeModal();
  },

  openModal(content) {
    this.setState({ modal: content });
  },

  closeModal() {
    this.setState({ modal: null });
  }

});

export default CrudList;
