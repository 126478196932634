import axios from 'axios';

import config from './config';


//Add base URL
axios.interceptors.request.use(
  function addBaseUrlSuccess(req) {
    if (req.url[0] === '/') {
      req.url = config.apiUrl + req.url;
    }

    return req;
  }
);


//Add Authorization header
axios.interceptors.request.use(
  function addAuthHeadersSuccess(req) {
    if (!req.headers['Authorization']) {
      var token = localStorage.getItem('accessToken');
      if (token) {
        req.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return req;
  }
);


//Handle errors; redirect to login on Unauthorized
axios.interceptors.response.use(
  function success(res) {
    return res;
  },

  function error(res) {
    let err = (res.response && res.response.data && res.response.data.error);

    return Promise.reject(err || res);
  }
);


export default axios;
