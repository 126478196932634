import React, { useState } from 'react';

import actions from '../../../../actions';

import LangSelectSingle from '../../lang-select-single';
import ApiButton from '../../../api-button';
import Modal from '../../../modal';
import ImportCsvButton from '../../../import-csv-button';

const modalTitle = 'Import translations';


export default function TranslationCsvImportModal({ app, fetchItems, closeModal }) {
  const [state, setState] = useState({
    step: 'file',
    data: [],
    detectedLangNames: [],
    selectedLangName: null,
  });

  function onReceiveData(result) {
    const { data } = result;
    const { fields } = result.meta;

    // Filter to fields that follow the convention `content_${langName}`
    const detectedLangNames = fields.reduce((memo, fieldName) => {
      const prefix = fieldName.slice(0, 8);
      if (prefix !== 'content_') return memo;

      const langName = fieldName.slice(8);
      if (!langName) return memo;

      return [...memo, langName];
    }, []);

    if (!detectedLangNames.length) {
      window.alert('No languages were detected in the given file. Please check and try again');
      
      setState({
        ...state,
        step: 'file',
      });

      return;
    };

    setState({
      ...state,
      step: 'preview',
      data,
      detectedLangNames,
      selectedLangName: detectedLangNames.slice(-1)[0],
    });
  }

  function onChangeLang(event) {
    setState({
      ...state,
      selectedLangName: event.target.value,
    });
  }

  async function importItems() {
    const { data, selectedLangName } = state;
    
    for (const row of data) {
      const { path } = row;
      const text = row[`content_${selectedLangName}`];

      await actions.items.update(app.name, selectedLangName, path, { draft: text });
    }

    fetchItems();
    closeModal();
  }



  if (state.step === 'file') return (
    <Modal title={modalTitle}>
      Select a file

      <ImportCsvButton
        onReceiveData={onReceiveData}
      />
    </Modal>
  )
  
  if (state.step === 'preview') {
    const { detectedLangNames, selectedLangName, data } = state;

    return (
      <Modal
        title={modalTitle}
        footer={(
          <ApiButton
            onClick={importItems}
          >
            Import
          </ApiButton>
        )}
      >
        <h5>Select the language you want to import:</h5>
        <LangSelectSingle
          langs={app.langs.filter(({ name }) => detectedLangNames.includes(name))}
          valueType="name"
          name="lang"
          value={selectedLangName}
          onChange={onChangeLang}
        />

        <h5 style={{ marginTop: 40 }}>Content preview</h5>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>
            {data.slice(0, 10).map((row) => (
              <tr key={row.path}>
                <td className="text-muted">{row.path}</td>
                <td>{row[`content_${selectedLangName}`]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    )
  }
}
