/**
 * @param {Object} app
 * @param {String} langId
 *
 * @return {String} title
 */
export const getById = function(app, langId) {
  const lang = app.langs.find(lang => lang.id === langId);

  if (!lang) throw new Error(`Language not found: ${langId}`);

  return lang;
};


/**
 * @param {Object} app
 * @param {String} langName
 *
 * @return {String} title
 */
export const getByName = function(app, langName) {
  const lang = app.langs.find(lang => lang.name === langName);

  if (!lang) throw new Error(`Language not found: ${langName}`);

  return lang;
};


/**
 * @param {Object} app
 * @param {String} langName
 *
 * @return {String} title
 */
export const title = function(app, langName) {
  const lang = app.langs.find(lang => lang.name === langName);

  return lang ? lang.title : '';
};


/**
 * @param {Object} app
 *
 * @return {Object}
 */
export const titlesById = function(app) {
  return app.langs.reduce((memo, lang) => {
    memo[lang.id] = lang.title;
    return memo;
  }, {});
};


/**
 * @param {Object} app
 *
 * @return {Object}
 */
export const namesById = function(app) {
  return app.langs.reduce((memo, lang) => {
    memo[lang.id] = lang.name;
    return memo;
  }, {});
};
