import React from 'react';
import PropTypes from 'prop-types';


class Form extends React.Component {
  
  static propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func,
    errors: PropTypes.object,
    initialValue: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      data: props.initialValue || {},
      errors: {}
    };

    //Bind methods
    [
      'getFieldProps', 
      'onChange', 
      'onSubmit'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: Object.assign(this.state.errors, nextProps.errors)
      });
    }
  }

  render() {
    const {children, onSubmit, initialValue, errors, ...rest} = this.props;
    const { data } = this.state;

    return children({
      getProps: this.getFieldProps,
      submit: this.onSubmit,
      ...rest,
      formData: data,
    });
  }

  getFieldProps(name) {
    const {data, errors} = this.state;

    return {
      name: name,
      value: (data && data[name]) || '',
      onChange: this.onChange,
      error: errors && errors[name],
      label: name
    };
  }

  onChange(event) {
    const {name, value} = event.target;
    const {data} = this.state;

    this.setState({
      data: Object.assign({}, data, { [name]: value })
    });
  }

  onSubmit(event) {
    event.preventDefault();

    const {onSubmit, validate} = this.props;
    const {data} = this.state;

    let errors = {};

    if (validate) {
      errors = validate(data);

      this.setState({ errors });
    }
    
    if (!Object.keys(errors).length) {
      onSubmit(data);
    }
  }

};


export default Form;
