import React from 'react';
import PropTypes from 'prop-types';


const ExportedContentModal = ({container, json, onClose}) => (

  <div>
    <div className="modal-header">
      <h4 className="modal-title">{container} <small>content as JSON</small></h4>
    </div>
    <div className="modal-body">
      <textarea 
        className="form-control" 
        value={json} 
        style={{height: 400}}
        onChange={() => {}}
      />
    </div>
    <div className="modal-footer">
      <button 
        className="btn btn-primary"
        type="button"
        onClick={onClose}
      >
        OK
      </button>
    </div>
  </div>
  
);


ExportedContentModal.propTypes = {
  container: PropTypes.string.isRequired,
  json: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};


export default ExportedContentModal;
