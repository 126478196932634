/**
 * A checklist form control.
 * Renders child components as a list of items with checkboxes.  Each child should have an 
 * "value" property. This is added/removed from an array on the form and used to control whether 
 * the item is checked or not.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';


export default class FormSelect extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
  }

  render() {
    const {options, value} = this.props;

    return (
      <ReactSelect
        options={options}
        value={options.find(option => option.value === value)}
        onChange={this.onChange}
      />
    );
  }

  onChange = (selectedOption) => {
    const {name} = this.props;

    this.props.onChange({
      target: {
        name: name,
        value: selectedOption.value
      }
    });
  }

};
