import React from 'react';
import PropTypes from 'prop-types';

import ApiForm from '../api-form';
import Field from '../form/bootstrap-field';


const PasswordForm = ({onSubmit, onSuccess}) => (

  <ApiForm
    onSubmit={onSubmit}
    onSuccess={onSuccess}
  >
    {({getProps, submit, errMsg, loading}) => 

      <form onSubmit={submit}>
        <Field 
          {...getProps('currentPassword')}
          type="password"
          label="Current password"
          required
        />

        <Field 
          {...getProps('password')}
          type="password"
          label="New password"
          required
        />

        <Field 
          {...getProps('confirmPassword')}
          type="password"
          label="Confirm new password"
          required
        />

        {errMsg &&
          <div className="alert alert-danger">
            {errMsg}
          </div>
        }

        <button 
          className="btn btn-default"
          type="submit"
          disabled={loading}
        >
          {loading 
            ? <i className="fa fa-spinner fa-spin"/>
            : 'Update password'
          }
        </button>
      </form>
      
    }
  </ApiForm>
  
);


PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default PasswordForm;
