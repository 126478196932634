import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {WurdText, WurdMarkdown} from 'wurd-react';

import actions from '../../../actions';

import CrudList from '../../crud-list';
import LangForm from './lang-form';


const LangItem = ({app, item}) => {
  const parentLang = item.extends
    ? app.langs.find(lang => lang.id === item.extends)
    : null;

  return (
    <div>
      {parentLang &&
        <em className="pull-right">
          Extends {parentLang.title}
        </em>
      }
      
      <h4>{item.title} <small>{item.name}</small></h4>
    </div>
  );
};


class AppDetailLangs extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const {app} = this.props;

    return (
      <div>
        <h3><WurdText id="app.langs.title"/></h3>
        <WurdMarkdown id="app.langs.intro"/>

        <CrudList
          items={app.langs}
          Form={LangForm}
          actions={actions.apps.langs(app.name)}
          onChange={this.onChange}
          formProps={{app}}
        >
          <LangItem app={app} />
        </CrudList>
      </div>
    );

  }

  onChange() {
    const {app, dispatch} = this.props;

    dispatch(actions.apps.fetch(app.name))
  }

};


AppDetailLangs.propTypes = {
  app: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};


export default AppDetailLangs;
