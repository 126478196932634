import React, {Component} from 'react';
import {connect} from 'react-redux';

import actions from '../actions';

import {Link} from 'react-router-dom';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem, Modal} from 'react-bootstrap';
import Avatar from './avatar';
import Spinner from './spinner';
import LoginForm from './login';


class Layout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modal: null
    };

    //Bind methods
    [
      'openModal',
      'closeModal',
      'init',
      'logout'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const {dispatch} = this.props;

    dispatch(actions.user.fetch())
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {user} = this.props;
    const {loading} = this.state;

    if (loading) return <Spinner />;

    return (user) ? this.renderMain() : this.renderLogin();
  }

  renderMain() {
    const {component: Component, user, ...rest} = this.props;
    const {modal} = this.state;
    const {appName} = this.props.match.params;

    return (
      <div>
        <Navbar>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/projects">Wurd</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem onClick={this.goTo.bind(this, '/projects')}>Projects</NavItem>

              {appName &&
                <NavItem><strong style={{color: '#000', fontSize: '1.4em'}}>{appName}</strong></NavItem>
              }
            </Nav>

            <Nav pullRight>
              <NavDropdown id="account" noCaret title={<Avatar user={user} size={30} className="gravatar-pic" />}>
                <MenuItem disabled>{user.email}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={this.goTo.bind(this, '/account')}>Your account</MenuItem>
                <MenuItem onClick={this.logout}>Log out</MenuItem>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="container">
          <Component
            openModal={this.openModal}
            closeModal={this.closeModal}
            {...rest}
          />
        </div>

        <Modal show={!!modal} onHide={this.closeModal} backdrop>
          {modal}
        </Modal>
      </div>
    );
  }

  renderLogin() {
    const {component: Component, user, ...rest} = this.props;

    return (
      <div className="col-sm-6 col-sm-offset-3">
        <LoginForm {...rest} />
      </div>
    );
  }

  logout() {
    this.props.dispatch(actions.user.logout());
  }

  goTo(route) {
    this.props.history.replace(route)
  }

  openModal(content) {
    this.setState({
      modal: content
    });
  }

  closeModal() {
    this.setState({ 
      modal: null
    });
  }

}


const mapStateToProps = ({user}) => {
  return {
    user
  };
};


export default connect(mapStateToProps)(Layout);
