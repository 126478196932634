import api from '../api';
import errHandler from './_errhandler';


export const receive = user => {
  return {
    type: 'USER_RECEIVE',
    payload: user
  };
};

export const fetch = () => {
  return (dispatch) => {
    let token = localStorage.getItem('accessToken');
    
    //No accesstoken so treat user as logged out
    if (!token) {
      dispatch(receive(null));
      return Promise.resolve(null);
    }

    //Fetch full user info
    return api.get('/auth/userinfo')
      .then(res => {
        let user = res.data;

        dispatch(receive(user));

        return user;
      })
      .catch(errHandler);
  };
};

export const update = (data) => {
  return (dispatch, getState) => {
    const {user} = getState();

    return api.put(`/users/${user.id}`, data)
      .then(res => {
        const updatedUser = res.data;

        dispatch(receive(updatedUser));

        return updatedUser;
      })
      .catch(errHandler);
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    let apiConfig = {
      headers: {
        'Authorization': 'Basic ' + btoa(email+':'+password),
        'X-Requested-With': 'XMLHttpRequest' //Prevents Safari displaying BasicAuth popup)
      }
    };

    return api.post('/auth/token', {}, apiConfig)
      .then(res => {
        let {accessToken, user} = res.data;

        localStorage.setItem('accessToken', accessToken);

        dispatch(receive(user));

        return user;
      })
      .catch(errHandler);
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.clear();

    dispatch(receive(null));
  };
};

export const forgotPassword = (email) => {
  return (dispatch) => {
    return api.post('/auth/forgot-password', { email })
      .then(res => {
        console.log(res)
      })
      .catch(errHandler);
  };
};


export const resetPassword = (data) => {
  return (dispatch) => {
    return api.post('/auth/reset-password', data)
      .then(res => {
        console.log(res)
      })
      .catch(errHandler);
  };
};
