import React from 'react';

import Avatar, {getRandomColor} from 'react-avatar';


export default ({user, ...rest}) => (
  <Avatar 
    email={user.email}
    name={user.name} 
    size={50} 
    textSizeRatio={2.5} 
    color={getRandomColor(user.email)}
    round
    {...rest} 
  />
);
