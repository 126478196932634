import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, MenuItem} from 'react-bootstrap';

import helpers from '../../../helpers';
import actions from '../../../actions';


const styles = {
  truncate: {
    wordWrap: 'break-word', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  }
};


class AppContentItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editing: false
    };
  }

  render() {
    const {item, langNamesById} = this.props;
    const {editing} = this.state;

    return (
      <tr 
        className={`${item.state === 'draft' && 'warning'}`}
      >
        <td className="col-sm-4" style={styles.truncate}>
          {item.path} <span className="text-muted">{langNamesById[item.langId]}</span>
        </td>

        {editing
          ? this.renderEditor(item)
          : this.renderDraft(item)
        }

        {editing
          ? this.renderEditorButtons(item)
          : this.renderOtherButtons(item)
        }
      </tr>
    );
  }

  renderDraft(item) {
    return (
      <td className="col-sm-6"
        onClick={this.toggleEdit}
        style={{cursor: 'pointer'}}
      >
        <div 
          style={{ 
            whiteSpace: 'pre-wrap',
            maxHeight: 200,
            overflow: 'auto',
          }}
        >
          {item.draft}
        </div>
      </td>
    );
  }

  renderEditor(item) {
    return (
      <td className="col-sm-6" style={styles.truncate}>
        <textarea 
          ref="draftContent" //Refactor - don't use refs
          className="form-control" 
          tabIndex="0"
          //onBlur={this.toggleEdit}
          defaultValue={item.draft} 
          style={{height: 200, padding: '2px 4px'}} 
          autoFocus
        />
      </td>
    );
  }

  renderEditorButtons(item) {
    return (
      <td className="col-sm-2">
        <button 
          title="Save changes"
          type="button" 
          className="btn btn-success btn-sm"
          onClick={this.save}
        >
          <i className="fa fa-fw fa-check"/>
        </button>
        <button 
          title="Cancel changes"
          type="button" 
          className="btn btn-danger btn-sm"
          onClick={this.toggleEdit}
        >
          <i className="fa fa-fw fa-times"/>
        </button>

        <div className="pull-right">
          <DropdownButton id="itemMenu" title="" pullRight className="btn-sm">
            <MenuItem onSelect={this.delete}><i className="fa fa-fw fa-trash" /> Delete item</MenuItem>
          </DropdownButton>
        </div>
      </td>
    );
  }

  renderOtherButtons(item) {
    return (
      <td className="col-sm-2 text-right">
        {item.state === 'draft' &&
          <button 
            type="button" 
            className="btn btn-success btn-sm"
            onClick={this.publish}
          >
            <i className="fa fa-fw fa-check"/> Publish
          </button>
        }
      </td>
    );
  }

  toggleEdit = () => {
    const {editing} = this.state;

    this.setState({ editing: !editing });
  }

  save = () => {
    const draftContent = this.refs.draftContent.value;

    this.sendUpdate({
      draft: draftContent
    }).then(this.toggleEdit);
  }

  publish = () => {
    this.sendUpdate({
      state: 'published'
    });
  }

  sendUpdate = (data) => {
    const {app, item, onChange} = this.props;

    const langName = helpers.langs.getById(app, item.langId).name;

    //TODO: Refactor; store items in redux and update via redux actions so changes are propagated properly
    return actions.items.update(app.name, langName, item.path, data)
      .then(onChange);
  }

  delete = () => {
    const {app, item, onChange} = this.props;

    const langName = helpers.langs.getById(app, item.langId).name;

    const confirmed = window.confirm('Are you sure you want to delete this item? This cannot be undone.');
    if (!confirmed) return;

    return actions.items.deleteItem(app.name, langName, item.path)
      .then(onChange);
  }

};


AppContentItem.propTypes = {
  app: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  langNamesById: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};


export default AppContentItem;
