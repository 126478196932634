import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import actions from '../../actions';

import {Tabs, Tab} from 'react-bootstrap';
import Spinner from '../spinner';
import NotFound from '../not-found';

import QuickStartPage from './quickstart';
import ContentPage from './content';
import LanguagesPage from './languages';
import TeamPage from './team';


class ProjectLayout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null
    };

    //Bind methods
    [
      'selectSection'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  componentDidMount() {
    const {appName, section} = this.props;

    //Set default tab
    if (!section) this.selectSection('quickstart');

    //Load content
    this.props.dispatch(actions.apps.fetch(appName))
      .catch(err => {
        this.setState({ error: err.message });
      });
  }

  render() {
    const {app, section, dispatch} = this.props;
    const {error} = this.state;

    if (error) return <NotFound />;

    if (app === undefined) return <Spinner />;
    if (!app) return <NotFound />;

    return (
      <div>
        <Tabs activeKey={section} id="sections" onSelect={this.selectSection}>
          <Tab eventKey="quickstart" title="Quick Start">
            <QuickStartPage {...this.props} />
          </Tab>
          <Tab eventKey="content" title="Content">
            <ContentPage {...this.props} />
          </Tab>
          <Tab eventKey="team" title="Team">
            <TeamPage app={app} dispatch={dispatch} />
          </Tab>
          <Tab eventKey="languages" title="Languages">
            <LanguagesPage app={app} dispatch={dispatch} />
          </Tab>
        </Tabs>
      </div>
    );
  }

  selectSection(section) {
    const {appName, history} = this.props;

    history.push(`/projects/${appName}/${section}`);
  }

};


ProjectLayout.propTypes = {
  appName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  app: PropTypes.object,
  section: PropTypes.string
};


const mapStateToProps = ({user,apps}, {match}) => {
  const {appName, section, subSection} = match.params;

  return {
    user: user,
    appName: appName,
    app: apps[appName],
    section: section,
    subSection: subSection
  };
};


export default connect(mapStateToProps)(ProjectLayout);
