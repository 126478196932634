import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {values, sortBy} from 'lodash';

import actions from '../../actions';

import {Link} from 'react-router-dom';
import NewAppModal from './new-app-modal';


class ProjectList extends Component {

  componentDidMount() {
    this.props.dispatch(actions.apps.list());
  }
  
  render() {
    const {apps} = this.props;

    return (
      <div>
        <div className="page-header">
          <div className="pull-right">
            <button className="btn btn-primary" onClick={this.createApp}><i className="fa fa-plus"/> New project</button>
          </div>

          <h2>Projects</h2>
        </div>

        {apps.length ? this.renderApps(apps) : this.renderNoResults()}
      </div>
    );
  }

  renderApps(apps) {
    return (
      <div className="list-group">
        {sortBy(apps, 'name').map(app => 
          <Link to={`/projects/${app.name}`} key={app.name} className="list-group-item">
            <h4>{app.name}</h4>
          </Link>
        )}
      </div>
    );
  }

  renderNoResults() {
    return (
      <div className="well text-center">
        <h3>You don't have any projects yet</h3>
        <div><p>Projects hold the text and image content for your website or app.</p></div>

        <button className="btn btn-primary" onClick={this.createApp}>
          <i className="fa fa-plus"/> Create your first project
        </button>
      </div>
    );
  }

  createApp = () => {
    const {dispatch, openModal} = this.props;
    
    openModal(
      <NewAppModal
        dispatch={dispatch}
        onSuccess={this.onAppCreated}
      />
    );
  }

  onAppCreated = (app) => {
    const {closeModal, history} = this.props;

    closeModal();

    history.push(`/projects/${app.name}`);
  }

};


ProjectList.propTypes = {
  apps: PropTypes.array.isRequired
};


const mapStateToProps = ({apps}) => {
  return {
    apps: values(apps)
  };
};


export default connect(mapStateToProps)(ProjectList);
