import React, {Component} from 'react';
import {connect} from 'react-redux';

import actions from '../../actions';

import Avatar from '../avatar';
import PasswordForm from './password-form';


class Account extends Component {

  constructor(props) {
    super(props);

    this.state = {
      passwordFormSuccess: false
    };

    //Bind methods
    [
      'onPasswordFormSubmit',
      'onPasswordFormSuccess'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  render() {
    const {user} = this.props;
    const {passwordFormSuccess} = this.state;

    return (
      <div>
        <div className="page-header">
          <h2>Your Account</h2>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <h4>Profile</h4>
          </div>
          <div className="col-sm-6">
            <div className="text-center">
              <Avatar user={user} size={100} className="gravatar-pic" />
              <div className="text-muted" style={{marginTop: 10}}>
                Update your profile picture at <a href="https://gravatar.com/" target="_blank" rel="noopener noreferrer">Gravatar</a>.
              </div>
            </div>

            <form style={{marginTop: 40}}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input id="email" type="email" className="form-control" disabled value={user.email} />
              </div>
              <div className="form-group">
                <label>Name</label>
                <input id="name" type="text" className="form-control" disabled value={`${user.firstName} ${user.lastName}`} />
              </div>

              <button className="btn btn-default" type="submit" disabled>Save</button>
            </form>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-4">
            <h4>Password</h4>
          </div>
          <div className="col-sm-6">
            {passwordFormSuccess 
              ? <div className="alert alert-success">
                  Your password has been updated.
                </div>
              : <PasswordForm
                  onSubmit={this.onPasswordFormSubmit}
                  onSuccess={this.onPasswordFormSuccess}
                />
            }
          </div>
        </div>
      </div>
    );
  }

  onPasswordFormSubmit(data) {
    const {dispatch} = this.props;

    return dispatch(actions.user.update(data));
  }

  onPasswordFormSuccess(data) {
    this.setState({ passwordFormSuccess: true });
  }

};


const mapStateToProps = ({user}) => {
  return {
    user
  };
};


export default connect(mapStateToProps)(Account);
