import React, { useState } from 'react';
import wurd from 'wurd-react';

import actions from '../../actions';

import Form from '../form';
import Field from './field';

const cms = wurd.block('login.forgotPassword');


export default function ForgotPasswordForm({
  setView,
  dispatch,
}) {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  function onSubmit(data) {
    const { email } = data;

    setLoading(true);

    dispatch(actions.user.forgotPassword(email))
      .then(() => setView('resetPassword'))
      .catch(err => {
        setLoading(false);
        setMessage(err.message);
      });
  }
  
  return (
    <Form onSubmit={onSubmit}>
      {({getProps, submit}) => (
          <form onSubmit={submit}>
            <div className="modal-header">
              <button
                type="button"
                className="btn btn-link pull-right"
                onClick={() => setView('login')}
              >
                <cms.Text id="loginBtn" />
              </button>
              
              <h4 className="modal-title"><cms.Text id="title" /></h4>
            </div>
            <div className="modal-body">
              <Field 
                {...getProps('email')}
                type="email"
                placeholder="Email"
                icon="envelope"
                autoFocus
                required
              />

              {message && 
                <div className="alert alert-danger">
                  <i className="fa fa-times-circle"/> {message}
                </div>
              }

              <button 
                className="btn btn-primary btn-flat btn-block"
                type="submit"
                value="Log in"
                disabled={loading}
              >
                {loading 
                  ? <i className="fa fa-spinner fa-spin"/>
                  : <cms.Text id="submitBtn" />
                }
              </button>
            </div>
          </form>
        )}
    </Form>
  );
}
