/**
 * Creates endpoints for managing a list of items
 */
import errHandler from './_errhandler';


/**
 * @param {Object} api              Axios API instance
 * @param {String} endpoint
 */
export default function(api, endpoint) {
  return {
    /**
     */
    read: function() {
      return api.get(endpoint)
        .then(res => res.data)
        .catch(errHandler)
    },

    /**
     * @param {Object} data
     */
    create: function(data) {
      return api.post(endpoint, data)
        .then(res => res.data)
        .catch(errHandler)
    },

    /**
     * @param {String} itemId
     * @param {Object} data
     */
    update: function(itemId, data) {
      return api.put(`${endpoint}/${itemId}`, data)
        .then(res => res.data)
        .catch(errHandler)
    },

    /**
     * @param {String} itemId
     */
    delete: function(itemId) {
      return api.delete(`${endpoint}/${itemId}`)
        .then(res => res.data)
        .catch(errHandler)
    }
  }
};
