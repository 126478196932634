import React from 'react';
import wurd from 'wurd-react';

import { getPermissionsFromRole, getRoleFromPermissions } from '../../../helpers/apps';

import ApiForm from '../../api-form';
import Field from '../../form/bootstrap-field';
import RadioList from '../../form/radiolist';
import MemberFormLangSelect from './member-form-lang-select';

const cms = wurd.block('app.team.memberForm');


// Props are passed by the CrudList component
export default function AppMemberForm({
  onSubmit,
  onSuccess,
  mode,
  onDelete,
  app,
  initialValue,
}) {
  // Translate permissions to role
  initialValue.role = getRoleFromPermissions(initialValue.permissions);

  async function handleSubmit(data) {
    // Translate role to permissions
    if (data.role) {
      data.permissions = getPermissionsFromRole(data.role);
    }

    return onSubmit(data);
  }

  return (
    <ApiForm
      initialValue={initialValue}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {({getProps, submit, errMsg, loading}) => 

        <form onSubmit={submit}>
          <div className="modal-header">
            <h4 className="modal-title">
              <cms.Text id={`${mode}.title`} vars={{ email: initialValue.email }} />
            </h4>
          </div>
          <div className="modal-body">
            {mode === 'create' &&
              <Field 
                {...getProps('email')}
                label={<cms.Text id="email.label" />}
                placeholder={cms.text('email.placeholder')}
                autoFocus
                required
              />
            }

            <Field 
              {...getProps('role')}
              label="Role"
            >
              <RadioList {...getProps('role')}>
                {['editor', 'publisher', 'admin'].map((role) => (
                  <div key={role} value={role}>
                    <div><cms.Text id={`role.${role}.label`} /></div>
                    <div><small className="text-muted"><cms.Text id={`role.${role}.help`} /></small></div>
                  </div>  
                ))}
              </RadioList>
            </Field>

            <Field
              {...getProps('langIds')}
              label="Languages"
            >
              <MemberFormLangSelect
                {...getProps('langIds')}
                app={app}
              />
            </Field>

            {errMsg &&
              <div className="alert alert-danger">{errMsg}</div>
            }
          </div>
          <div className="modal-footer">
            {onDelete && 
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={onDelete}
              >
                <cms.Text id="delete.btn" />
              </button>
            }

            <button 
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading 
                ? <i className="fa fa-spinner fa-spin"/>
                : <cms.Text id={`${mode}.btn`} />
              }
            </button>
          </div>
        </form>

      }
    </ApiForm>
  );  
};
