import React from 'react';
import PropTypes from 'prop-types';

import ApiForm from '../../api-form';
import Field from '../../form/bootstrap-field';
import LangSelectSingle from '../lang-select-single';


const AppItemForm = ({ app, initialValue, onSubmit, onSuccess }) => {

  return (
    <ApiForm 
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      initialValue={initialValue}
    >
      {({getProps, submit, errMsg, loading}) => 
        <form onSubmit={submit}>
          <div className="modal-header">
            <h4 className="modal-title">Add a content item</h4>
          </div>
          <div className="modal-body">
            <Field
              {...getProps('langName')}
              label="Language"
              required
            >
              <LangSelectSingle
                valueType="name"
                langs={app.langs}
              />
            </Field>

            <Field 
              {...getProps('path')}
              label="Path"
              placeholder="container.itemName"
              autoFocus
              required
            />

            <Field 
              {...getProps('draft')}
              label="Content"
              placeholder="Lorem ipsum"
              required
            />

            {errMsg &&
              <div className="alert alert-danger">{errMsg}</div>
            }
          </div>
          <div className="modal-footer">
            <button 
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading 
                ? <i className="fa fa-spinner fa-spin"/>
                : 'Create item'
              }
            </button>
          </div>
        </form>
      }
    </ApiForm>
  );
  
};


AppItemForm.propTypes = {
  app: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default AppItemForm;
