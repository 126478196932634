import React, { useState } from 'react';
import PapaCsv from 'papaparse';
import { keyBy } from 'lodash';

import actions from '../../../../actions';

import Modal from '../../../modal';
import ExportButton from '../../../export-button';
import ExportCsvForm from './export-form';

const styles = {
  buttonContainer: {
    marginTop: 40
  },
};


export default function TranslationCsvExportModal({ app, closeModal }) {
  const [state, setState] = useState({
    step: 'form',
    sourceLang: null,
    destinationLang: null,
  });

  async function handleFormSubmit(data) {
    const { sourceLangId, destinationLangId } = data;

    // Validate
    if (!sourceLangId || !destinationLangId) throw new Error('Select a source and destination language');
    if (sourceLangId === destinationLangId) throw new Error('Source and destination languages must be different');

    // Store the selected source and destination languages
    const sourceLang = app.langs.find((lang) => lang.id === sourceLangId);
    if (!sourceLang) throw new Error('Source language not found');

    const destinationLang = app.langs.find((lang) => lang.id === destinationLangId);
    if (!destinationLang) throw new Error('Destination language not found');

    setState({
      step: 'download',
      sourceLang,
      destinationLang,
    });

    return data;
  }

  async function downloadFile() {
    const { sourceLang, destinationLang } = state;
    
    const sourceItems = await actions.items.fetch(app.name, { lang: sourceLang.name });
    const destinationItems = await actions.items.fetch(app.name, { lang: destinationLang.name });
    
    // Format as CSV
    const destinationItemsByPath = keyBy(destinationItems, 'path');
    
    const csvRows = sourceItems.map((item) => {
      const destinationItem = destinationItemsByPath[item.path];

      return {
        path: item.path,
        [`content_${sourceLang.name}`]: item.content,
        [`content_${destinationLang.name}`]: destinationItem?.content,
      };
    });

    return PapaCsv.unparse(csvRows);
  }


  if (state.step === 'form') return (
    <ExportCsvForm
      app={app}
      onSubmit={handleFormSubmit}
    />
  )

  if (state.step === 'download') return (
    <Modal title="Export content as CSV">
      <p>Export content as CSV for easy bulk updating and translating.</p>

      <div className="text-center" style={styles.buttonContainer}>
        <ExportButton
          bsType="primary"
          filename={`${app.name}_${state.sourceLang.name}_${state.destinationLang.name}.csv`}
          onClick={downloadFile}
        />
      </div>
    </Modal>
  );
}
