import React from 'react';
import PropTypes from 'prop-types';

import Form from './form';


class ApiForm extends React.Component {
  
  static propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,  // Should return a promise
    onSuccess: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      errMsg: null,
      message: null,
      loading: false,
      apiErrors: null
    };

    //Bind methods
    [
      'onSubmit'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  render() {
    const {children, onSubmit, onSuccess, ...rest} = this.props;
    const {errMsg, apiErrors, loading} = this.state;

    return (
      <Form
        onSubmit={this.onSubmit}
        errors={apiErrors}
        errMsg={errMsg}
        loading={loading}
        {...rest}
      >
        {children}
      </Form>
    );
  }

  onSubmit(data) {
    const {onSubmit, onSuccess} = this.props;

    this.setState({
      loading: true,
      apiErrors: null
    });

    //Login
    onSubmit(data)
      .then(onSuccess)
      .catch(err => {
        this.setState({
          errMsg: err.message,
          message: err.message,
          apiErrors: err.errors,
          loading: false
        })
      });
  }

};


export default ApiForm;
