import api from '../api';
import errHandler from './_errhandler';
import crudList from './_crud-list';


/**
 * Add/update apps in the store
 *
 * @param {App|App[]} apps
 */
export const receive = (apps) => {
  //Normalise single app to array
  if (!Array.isArray(apps)) {
    apps = [apps];
  }

  return {
    type: 'APPS_RECEIVE',
    payload: apps
  };
};


/**
 * List apps
 *
 * @param {Object} [params]
 */
export const list = (params = {}) => {
  return (dispatch, getState) => {
    const url = `/apps`;

    return api.get(url, { params: params })
      .then(res => {
        let apps = res.data;

        dispatch(receive(apps));

        return apps;
      })
      .catch(errHandler);
  };
};


/**
 * Get an app
 *
 * @param {String} appName
 */
export const fetch = (appName) => {
  return (dispatch, getState) => {
    const url = `/apps/${appName}`;

    return api.get(url)
      .then(res => {
        const app = res.data;

        dispatch(receive(app));

        return app;
      })
      .catch(errHandler);
  };
};


/**
 * Create an app
 */
export const create = (data) => {
  return (dispatch) => {
    const url = `/apps`;

    return api.post(url, data)
      .then(res => {
        const app = res.data;

        dispatch(receive(app));

        return app;
      })
      .catch(errHandler);
  }
};


/**
 * Import content to an app
 */
export const importJsonContent = (appName, langName, json) => {
  return (dispatch) => {
    const url = `/apps/${appName}/import?lang=${langName}`;

    //Check that it's valid JSON before submitting
    try {
      JSON.parse(json);
    } catch (err) {
      throw new Error('Invalid JSON content');
    }

    return api.post(url, { content: json })
      .catch(errHandler);
  }
};


/**
 * Create/update/delete languages
 */
export const langs = function(appName) {
  return crudList(api, `/apps/${appName}/langs`);
};


/**
 * Add/update/remove users
 */
export const members = function(appName) {
  return crudList(api, `/apps/${appName}/members`);
};
