import React from 'react';

import Select from '../form/select';


export default function LangSelectSingle({
  langs = [],
  valueType = 'id', // id or name
  ...rest
}) {
  function getOptions() {
    return langs.reduce((memo, lang) => {
      memo.push({ value: lang[valueType], label: `${lang.title} (${lang.name})` });
      
      return memo;
    }, []);
  }


  return (
    <Select
      {...rest}
      options={getOptions()}
    />
  );
}
