import React, { useState, useEffect } from 'react';
import {WurdText, WurdMarkdown} from 'wurd-react';

import helpers from '../../../helpers';
import actions from '../../../actions';

import CrudList from '../../crud-list';
import Avatar from '../../avatar';
import MemberForm from './member-form';


function MemberItem({
  langTitlesById,
  item
}) {
  const permissions = item.permissions.join(', ');
  const role = helpers.apps.getRoleFromPermissions(item.permissions);

  const languages = item.langIds.includes('*')
    ? 'All'
    : item.langIds.map(langId => langTitlesById[langId]).join(', ');

  return (
    <div>
      <div className="pull-right">
        {item.role !== 'owner' && role === 'editor' && (
          <span className="label label-info">Editor</span>
        )}
        {item.role !== 'owner' && role === 'publisher' && (
          <span className="label label-info">Publisher</span>
        )}
        {item.role !== 'owner' && role === 'admin' && (
          <span className="label label-primary">Admin</span>
        )}
        {item.role === 'owner' && (
          <span className="label label-success">Owner</span>
        )}
      </div>

      <Avatar user={item} style={{marginRight: 10}} className="pull-left" />
      <h4 className="list-group-item-heading">
        {item.email}
        {item.invited && <small style={{fontStyle: 'italic'}}> Invited</small>}
      </h4>
      <p className="list-group-item-text text-muted">
        Permissions: {permissions}<br />

        Languages: {languages}
      </p>
    </div>
  );
};


export default function AppDetailUsers({
  app,
}) {
  const [members, setMembers] = useState([]);

  async function fetchUsers() {
    const members = await actions.apps.members(app.name).read();
    setMembers(members);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const langTitlesById = helpers.langs.titlesById(app);

  const initialValue = {
    permissions: ['create', 'update'],
    langIds: ['*']
  };

  return (
    <div>
      <h3><WurdText id="app.team.title"/></h3>
      <WurdMarkdown id="app.team.intro"/>

      <CrudList
        items={members}
        Form={MemberForm}
        initialValue={initialValue}
        actions={actions.apps.members(app.name)}
        onChange={fetchUsers}
        canEdit={(item) => item.role !== 'owner'}
        formProps={{app}}
      >
        <MemberItem langTitlesById={langTitlesById} />
      </CrudList>
    </div>
  );
}
