import React, {Component} from 'react';
import PropTypes from 'prop-types';

import actions from '../../../actions';

import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {WurdText, WurdMarkdown} from 'wurd-react';

import QuickstartImportForm from './import-form';

import './index.css';


class AppDetailQuickStart extends Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    subSection: PropTypes.string
  }

  //Highlight code
  componentDidUpdate() {
    const hljs = window.hljs;
    if (!hljs || !document.querySelectorAll) return;

    document.querySelectorAll('.AppDetailQuickStart_docs pre code').forEach(hljs.highlightBlock);
  }

  render() {
    const {subSection} = this.props;

    return subSection ? this.renderSection(subSection) : this.renderList();
  }

  renderList() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right" style={{marginTop: 12}}>
              <Button onClick={this.importContent}>
                <i className="fa fa-upload"/> Import content
              </Button>
            </div>

            <h3><WurdText id="app.start.title"/></h3>
          </div>
        </div>

        <hr style={{marginTop: 4}}/>

        <WurdMarkdown id="app.start.intro"/>

        <div className="list-group">
          {/*<Link to="quickstart/nodejs" className="list-group-item">
            <h4 className="list-group-item-heading">Node.js</h4>
            <div className="list-group-item-text">For rendering your content on the server side</div>
          </Link>*/}
          <Link to="quickstart/react" className="list-group-item">
            <h4 className="list-group-item-heading">React</h4>
            <div className="list-group-item-text">For client side / single page apps built with React</div>
          </Link>
          {/*<Link to="quickstart/web" className="list-group-item">
            <h4 className="list-group-item-heading">Web</h4>
            <div className="list-group-item-text">For client side / single page apps built with any setup</div>
          </Link>*/}
        </div>
      </div>
    );
  }

  renderSection(section) {
    const {app} = this.props;

    return (
      <div className="AppDetailQuickStart_docs">
        <WurdMarkdown id={`docs.${section}`} vars={{ appName: app.name }} />
      </div>
    );
  }

  importContent = () => {
    const {app, openModal} = this.props;
    
    openModal(
      <QuickstartImportForm
        app={app} 
        onSubmit={this.onImportSubmit}
        onSuccess={this.onItemsCreated}
      />
    );
  }

  onImportSubmit = (data) => {
    try {
      const {app, dispatch} = this.props;
      const {langId, content} = data;

      if (!langId) throw new Error('Language is required');

      const lang = app.langs.find(lang => lang.id === langId);
      if (!lang) throw new Error('Language not found');

      return dispatch(actions.apps.importContent(app.name, lang.name, content))
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onItemsCreated = () => {
    const {appName, history, closeModal} = this.props;

    closeModal();
    
    history.push(`/projects/${appName}/content`);
  }

};


export default AppDetailQuickStart;
