/**
 * A simple Bootstrap form field.
 */
import React from 'react';


export default ({name, label, value, onChange, error, help, icon, children, ...inputProps}) => {
  const allInputProps = Object.assign({
    className: 'form-control',
    id: name,
    name: name,
    value,
    onChange
  }, inputProps);

  let inputComponent;
  if (children) {
    inputComponent = React.cloneElement(children, allInputProps);
  } else {
    inputComponent = <input {...allInputProps} />;
  }

  return (
    <div className={`form-group ${error && 'has-error'}`}>
      <label htmlFor={name}>{label}</label>
      {inputComponent}
      {error && 
        <div className="help-block">{error}</div>
      }
    </div>
  );
};
