import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';


export default class LangSelectSingle extends React.Component {

  static propTypes = {
    app: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const {value} = this.props;
    const options = this.getOptions();

    return (
      <ReactSelect
        options={options}
        value={options.find(option => option.value === value)}
        onChange={this.onChange}
        placeholder="Language"
      />
    );
  }

  getOptions = () => {
    const {app} = this.props;

    return app.langs.reduce((memo, lang) => {
      memo.push({ value: lang.name, label: `${lang.title} (${lang.name})` });
      
      return memo;
    }, [{ value: null, label: 'Default language' }]);
  }

  onChange = (selectedOption) => {
    this.props.onChange(selectedOption.value);
  }

};