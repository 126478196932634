import { combineReducers } from 'redux';
import user from './user';
import apps from './apps';


const appReducers = combineReducers({
  user,
  apps
});

export default appReducers;
