import React, {Component} from 'react';
import PropTypes from 'prop-types';

import actions from '../../actions';

import NewAppForm from './new-app-form';


class NewAppModal extends Component {

  constructor(props) {
    super(props);

    //Bind methods
    [
      'onSubmit'
    ].forEach(fnName => this[fnName] = this[fnName].bind(this));
  }

  render() {
    const {onSuccess} = this.props;

    return (
      <NewAppForm 
        onSubmit={this.onSubmit} 
        onSuccess={onSuccess}
      />
    );
  }

  onSubmit(data) {
    return this.props.dispatch(actions.apps.create(data));
  }

};


NewAppModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default NewAppModal;
