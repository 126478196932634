import React from 'react';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
//import history from './history';

import Layout from './components/layout';
import ProjectList from './components/project-list';
import Project from './components/project';
import Account from './components/account';
import NotFound from './components/not-found';


export default function Router() {
  return (
    <BrowserRouter component={Layout}>
      <div>
        <Switch>
          <Route exact path="/" render={props => <Redirect to="/projects" />} />
          <Route exact path="/projects" render={props => <Layout component={ProjectList} {...props} />} />
          <Route path="/projects/:appName/:section?/:subSection?" render={props => <Layout component={Project} {...props} />} />
          
          <Route exact path="/account" render={props => <Layout component={Account} {...props} />} />

          <Route component={NotFound} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
