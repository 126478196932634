import 'babel-polyfill'
import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import wurd from 'wurd-react';
import { marked } from 'marked';

import config from './config';
import store from './store';
import AppRouter from './routes';

import './index.css';

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>,
    document.getElementById('root')
  );
}

// Load content
wurd.connect('wurd-manage', {
  editMode: 'querystring',
  onLoad: render,
  markdown: marked,

  /* debug: true,
  ttl: 10_000, */
});

// Preload initial content
wurd.load('common,app,docs,login');