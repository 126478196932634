import React, {Component} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import wurd, { WurdText } from 'wurd-react';
import {DropdownButton, MenuItem} from 'react-bootstrap';

import helpers from '../../../helpers';
import actions from '../../../actions';

import Modal from '../../modal';
import ApiButton from '../../api-button';
import LangSelector from './lang-selector';
import ItemForm from './item-form';
import ImportForm from './import-form';
import ExportForm from './export-form';
import ExportModal from './export-modal';
import TranslationCsvExportModal from './translation-csv/export-modal';
import TranslationCsvImportModal from './translation-csv/import-modal';
import ContentItem from './content-item';

const cms = wurd.block('app.content');


/**
 * Parses query string for filters and returns them as a state update
 */
const getQueryParams = function(location) {
  const query = queryString.parse(location.search);

  return {
    path: query.path,
    langName: query.lang
  };
}


export default class AppDetailContent extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = Object.assign({
      items: null,
      path: undefined,
      langName: undefined
    }, getQueryParams(props.location));
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(getQueryParams(nextProps.location), this.fetchItems);
  }

  setPath = (event) => {
    event.preventDefault();

    const {history} = this.props;
    const {langName} = this.state;

    const path = event.target.elements.path.value;

    history.push({
      search: queryString.stringify({
        lang: langName,
        path: path
      })
    });
  }

  setLang = (lang) => {
    const {history} = this.props;
    const {path} = this.state;

    const langName = lang ? lang.name : undefined;

    history.push({
      search: queryString.stringify({
        path: path,
        lang: langName
      })
    });
  }

  fetchItems = () => {
    const {app} = this.props;
    const {path, langName} = this.state;

    const params = {
      path: path,
      lang: langName
    };

    actions.items.fetch(app.name, params)
      .then(items => {
        this.setState({ items: items });
      });
  }

  createItem = () => {
    const { app, openModal, closeModal } = this.props;
    const { langName, path } = this.state;

    const initialValue = {
      langName,
      path,
    };
    
    openModal(
      <ItemForm
        app={app}
        initialValue={initialValue}
        onSubmit={async (data) => {
          const { langName, path, draft } = data;

          if (!langName) throw new Error('Language is required');

          const lang = helpers.langs.getByName(app, langName);
          if (!lang) throw new Error('Language not found');

          await actions.items.createItem({
            app,
            lang,
            path,
            draft,
          });
        }}
        onSuccess={() => {
          this.fetchItems();
          closeModal();
        }}
      />
    );
  }

  importJsonContent = () => {
    const { app, dispatch, openModal, closeModal } = this.props;
    const { langName, path } = this.state;

    const initialValue = {
      langName,
      path,
    };
    
    openModal(
      <ImportForm
        app={app}
        initialValue={initialValue}
        onSubmit={async (data) => {
          const { langName, path, json } = data;
    
          if (!langName) throw new Error('Language is required');
          if (!path) throw new Error('Path is required');
    
          //Wrap the content in the path/container name
          const wrappedJson = `{ "${path}": ${json} }`;
    
          return dispatch(actions.apps.importJsonContent(app.name, langName, wrappedJson))
        }}
        onSuccess={() => {
          this.fetchItems();
          closeModal();
        }}
      />
    );
  }

  exportJsonContent = () => {
    const { app, openModal, closeModal } = this.props;
    const { langName, path } = this.state;

    const initialValue = {
      langName,
      path,
    };
    
    openModal(
      <ExportForm
        app={app} 
        initialValue={initialValue}
        onSubmit={async (data) => {
          const { langName, path } = data;

          if (!path) throw new Error('You must specify a section path to filter by');

          const content = await actions.content.fetch(app.name, path, { lang: langName });

          //The content returned is wrapped in the container name; unwrap it here
          const unwrappedContent = content[path];

          //Format/prettify with the JSON stringy with indentation
          const json = JSON.stringify(unwrappedContent, null, 2);

          openModal(
            <ExportModal
              container={path}
              json={json}
              onClose={closeModal}
            />
          );
        }}
        onSuccess={async () => {}}
      />
    );
  }

  exportTranslationCsv = () => {
    const { app, openModal, closeModal } = this.props;

    openModal(
      <TranslationCsvExportModal
        app={app}
        closeModal={closeModal}
      />
    );
  }

  importTranslationCsv = () => {
    const { app, openModal, closeModal } = this.props;

    openModal(
      <TranslationCsvImportModal
        app={app}
        fetchItems={this.fetchItems}
        closeModal={closeModal}
      />
    );
  }

  publishAll = async () => {
    const { openModal, closeModal } = this.props;
    const { items } = this.state;

    const draftItems = items.filter(({ state }) => state === 'draft');
    const numDraft = draftItems.length;
    if (!numDraft) return;

    openModal(
      <Modal
        title="Publish all"
        footer={(
          <>
            <ApiButton
              onClick={async () => closeModal()}
            >
              Cancel
            </ApiButton>
            <ApiButton
              bsType="success"
              onClick={async () => {
                for (const item of draftItems) {
                  await actions.items.publishItem(item);
                }
            
                this.fetchItems();
                closeModal();
              }}
            >
              Publish all
            </ApiButton>
          </>
        )}
      >
        <cms.Text id="publishAll.confirm" vars={{ numDraft }} />
      </Modal>
    );
  }

  render() {
    const { app } = this.props;
    const { items, path, langName } = this.state;
    const langNamesById = helpers.langs.namesById(app);

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right" style={{marginTop: 12}}>
              <button onClick={this.createItem} className="btn btn-primary">
                <i className="fa fa-plus"/> Add item
              </button>
              &nbsp;
              &nbsp;
              <DropdownButton id="actionsMenu" title="Actions" pullRight>
                <MenuItem onSelect={this.publishAll}><i className="fa fa-fw fa-check"/> Publish all</MenuItem>
                <MenuItem divider />
                <MenuItem onSelect={this.exportJsonContent}><i className="fa fa-fw fa-download"/> Export JSON content</MenuItem>
                <MenuItem onSelect={this.importJsonContent}><i className="fa fa-fw fa-upload"/> Import JSON content</MenuItem>
                <MenuItem divider />
                <MenuItem onSelect={this.exportTranslationCsv}><i className="fa fa-fw fa-download"/> Export translation CSV</MenuItem>
                <MenuItem onSelect={this.importTranslationCsv}><i className="fa fa-fw fa-upload"/> Import translation CSV</MenuItem>
              </DropdownButton>
            </div>

            <h3><WurdText id="app.content.title"/></h3>
          </div>
        </div>

        <hr style={{marginTop: 4}}/>

        <div className="row" style={{marginBottom: 24}}>
          <div className="col-sm-4">
            <LangSelector
              app={app}
              value={langName}
              onChange={name => this.setLang({ name })}
            />
          </div>
          <div className="col-sm-8">
            <form onSubmit={this.setPath}>
              <div className="input-group">
                <input 
                  name="path"
                  className="form-control"
                  placeholder="Find items by path e.g. 'container.item'"
                  autoFocus
                  defaultValue={path}
                />

                <span className="input-group-btn">
                  <button className="btn btn-primary" type="submit">Go</button>
                </span>
              </div>
            </form>
          </div>
        </div>

        {items && (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Path</th>
                <th>Content</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => 
                <ContentItem 
                  key={item.path} 
                  app={app} 
                  item={item} 
                  langNamesById={langNamesById} 
                  onChange={this.fetchItems}
                />
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
