import api from '../api';
import errHandler from './_errhandler';

import helpers from '../helpers';


export function createItem({ app, lang, path, draft }) {
  const data = { draft };
  const params = { lang: lang.name };

  return api.put(`/apps/${app.name}/items/${path}`, data, { params })
    .then(res => res.data)
    .catch(errHandler);
}


export function updateItem(item, data) {
  const { appId, langId, path } = item;

  const app = helpers.apps.getById(appId);
  if (!app) throw new Error(`app ${appId} not found`);

  const lang = helpers.langs.getById(app, langId);
  if (!lang) throw new Error(`lang ${langId} not found on app ${app.name}`);

  const params = { lang: lang.name };

  return api.put(`/apps/${app.name}/items/${path}`, data, { params })
    .then(res => res.data)
    .catch(errHandler);
}

export function publishItem(item) {
  return updateItem(item, { state: 'published' });
}


export const fetch = (appName, params = {}) => {
  return api.get(`/apps/${appName}/items`, { params: params })
    .then(res => res.data)
    .catch(errHandler);
};


// DEPRECATED; use updateItem
export const update = (appName, langName, itemPath, data) => {
  const params = {
    lang: langName
  };

  return api.put(`/apps/${appName}/items/${itemPath}`, data, { params: params })
    .then(res => res.data)
    .catch(errHandler);
};


export const deleteItem = (appName, langName, itemPath, data) => {
  const params = {
    lang: langName
  };

  return api.delete(`/apps/${appName}/items/${itemPath}`, { params: params })
    //.then(res => res.data)
    .catch(errHandler);
};
