/**
 * An export button that downloads a CSV file to the user's browser
 */
import React, { useState } from 'react';

import { Button } from 'react-bootstrap';


export default function ExportButton({
  onClick,                  // Function that should return a promise which provides CSV data when resolved
  filename = 'export.csv',
  children = 'Export',
}) {
  const [state, setState] = useState({
    step: 'wait',
    url: null,
  });

  function prepareCsv() {
    setState({ step: 'loading' });

    onClick()
      .then((data) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

        // IE
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename);

          setState({ step: 'wait' });
        } else {
          // Other browsers
          const url = URL.createObjectURL(blob);

          setState({
            step: 'ready',
            url,
          });
        }
      })
      .catch((err) => {
        window.alert(err.message);

        setState({ step: 'wait' });

        throw err;
      });
  }

  function downloadCsv() {
    setState({ step: 'wait' });
  }


  switch (state.step) {
    case 'loading':
      return (
        <Button
          bsStyle="warning"
        >
          <i className="fa fa-sync fa-spin" /> Loading
        </Button>
      );

    case 'ready':
      return (
        <Button
          bsStyle="success"
          href={state.url}
          target="_blank"
          download={filename}
          onClick={downloadCsv}
        >
          <i className="fa fa-download" /> Download
        </Button>
      )

    default:
      return (
        <Button
          bsStyle="primary"
          onClick={prepareCsv}
        >
          <i className="fa fa-download" /> {children}
        </Button>
      );
  }
}
