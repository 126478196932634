import React from 'react';
import PropTypes from 'prop-types';

import ApiForm from '../../api-form';
import Field from '../../form/bootstrap-field';
import Select from '../../form/select';


// Props are passed by the CrudList component
const AppLangForm = ({onSubmit, onSuccess, mode, deleteButton, onDelete, app, initialValue}) => {

  const langOptions = app.langs.reduce((memo, lang) => {
    if (initialValue.id !== lang.id) {
      memo.push({ value: lang.id, label: `${lang.title} (${lang.name})` });
    }

    return memo;
  }, []);

  return (
    <ApiForm
      initialValue={initialValue}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({getProps, submit, errMsg, loading}) => 

        <form onSubmit={submit}>
          <div className="modal-header">
            <h4 className="modal-title">
              {mode === 'create' ? 'Add a language' : `Update ${initialValue.title}`}
            </h4>
          </div>
          <div className="modal-body">
            <Field 
              {...getProps('title')}
              label="Name"
              placeholder="French"
              autoFocus
              required
            />

            <Field 
              {...getProps('name')}
              label="Code"
              placeholder="fr"
              required
            />

            <Field
              {...getProps('extends')}
              label="Parent language"
              help="Will be used if a content is not available in this language"
            >
              <Select
                {...getProps('extends')}
                options={langOptions}
              />
            </Field>

            {errMsg &&
              <div className="alert alert-danger">{errMsg}</div>
            }
          </div>
          <div className="modal-footer">
            {onDelete && 
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={onDelete}
              >
                {deleteButton || 'Delete'}
              </button>
            }

            <button 
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading 
                ? <i className="fa fa-spinner fa-spin"/>
                : (mode === 'create') ? 'Create language' : 'Update language'
              }
            </button>
          </div>
        </form>

      }
    </ApiForm>
  );
  
};


AppLangForm.propTypes = {
  app: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};


export default AppLangForm;
